<template>
  <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg"
       @click="$emit('click')">
    <path clip-rule="evenodd"
          d="M11.7915 10.2604L15.6829 14.1518C16.1057 14.5746 16.1057 15.2601 15.6829 15.6829C15.2601 16.1057 14.5746 16.1057 14.1518 15.6829L10.2604 11.7915C7.72346 13.5995 4.17869 13.3655 1.90265 11.0895C-0.634218 8.55261 -0.634218 4.43953 1.90265 1.90265C4.43953 -0.634217 8.55261 -0.634218 11.0895 1.90265C13.3655 4.17869 13.5995 7.72346 11.7915 10.2604ZM9.55834 3.43379C11.2496 5.12504 11.2496 7.86709 9.55834 9.55834C7.86709 11.2496 5.12504 11.2496 3.43379 9.55834C1.74254 7.86709 1.74254 5.12504 3.43379 3.43379C5.12504 1.74254 7.86709 1.74254 9.55834 3.43379Z"
          fill="#C4D0DA"
          fill-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: "IconMagnifier"
}
</script>

<style lang="scss" scoped>
svg {
  z-index: 10;
  cursor: pointer;

  &:hover {
    path {
      fill: #727e85;
    }
  }
}
</style>
