<template>
  <div class="switcher-wrapper">
    <button class="switcher__button" :class="{'switcher__button-active': activeButton === 1}" @click="$emit('percentage-toggle-switch', 1)">{{ firstButtonContent }}</button>
    <button class="switcher__button" :class="{'switcher__button-active': activeButton === 2}" @click="$emit('percentage-toggle-switch', 2)">{{ secondButtonContent }}</button>
  </div>
</template>

<script>
export default {
  name: "Switcher",
  props: {
    activeButton: {
      type: Number,
      default: 1
    },
    firstButtonContent: {
      type: String,
      default: '#'
    },
    secondButtonContent: {
      type: String,
      default: '%'
    }
  }
}
</script>

<style lang="scss" scoped>
.switcher {
  &-wrapper {
    border: 1px solid rgba(227, 233, 237, 1);
    border-radius: 4px;
    padding: 4px;
  }
  &__button {
    width: 28px;
    height: 28px;
    background-color: #ffffff;
    color: rgba(141, 156, 168, 1);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    &:first-of-type {
      margin-right: 4px;
    }
    &-active {
      background-color: #000000;
      color: #ffffff;
    }
  }
}
</style>
