<template>
  <div class="card-actions">
    <!--<Modal :title="modalTitle"-->
    <!--:modalText="modalText" />-->

    <IconMagnifier v-if="isPreview" @click="$emit('togglePreview')" />
    <ButtonExcel :exportToExcel="exportToExcel" />
    <Switcher
      v-if="showPercentageSwitcher"
      :active-button="activeSwitcherButton"
      :first-button-content="firstSwitcherButtonContent"
      :second-button-content="secondSwitcherButtonContent"
      @percentage-toggle-switch="$emit('toggle-percentage', $event)"
    />
  </div>
</template>

<script>

  import ButtonExcel from "../ui/buttons/ButtonExcel";
  import IconMagnifier from "@/components/ui/icons/IconMagnifier";
  import Switcher from "@/components/ui/switchers/Switcher.vue";

  export default {
    name: "Actions",
    props: {
      showPercentageSwitcher: {
        type: Boolean,
        default: false
      },
      activeSwitcherButton: {
        type: Number,
        default: 1
      },
      firstSwitcherButtonContent: {
        type: String,
        default: '#'
      },
      secondSwitcherButtonContent: {
        type: String,
        default: '%'
      },
      exportToExcel: {
        type: Function,
        default: () => {},
      },
      modalTitle: {
        type: String,
        default: "",
      },
      modalText: {
        type: String,
        default: "",
      },
      isPreview: Boolean
    },
    components: {
      IconMagnifier,
      ButtonExcel,
      Switcher
    },
  };
</script>

<style lang="scss">
  .card-actions {
    position: absolute;
    top: 16px;
    right: 20px;
    display: flex;
    align-items: center;

    div {
      margin-left: 8px;

      &:first-child {
        margin-left: 0;
      }
    }

    .btn-open--modal,
    .btn-open--excel {
      position: relative;
      top: 0;
      left: auto;
      right: auto;
    }
  }
</style>
