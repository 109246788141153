<template>
  <div :class="{xs12: isExportable, xl6: isExportable}" class="flex">

    <vac-card
      v-if="isExportable"
      :title="$t(label)"
      class="chart-widget">

      <ExcelPreviewModal
        v-if="isPreview"
        :data="preparedData"
        :header="label"
        @disable="togglePreview"
        @exportToExcel="exportToExcel"
      />
      <Actions v-if="isExportable" :export-to-excel="exportToExcel" @togglePreview="togglePreview" is-preview/>

      <line-chart-generator
        :chart-id="'line-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :chart-options="chartOptions"
        :height="500"
        :css-classes="'line-chart-exportable export'"
      />

    </vac-card>

    <vac-card
      v-else
      :style="{margin: '10px 0'}"
      :title="$t('Retailers Per Time')"
      class="chart-widget">

      <line-chart-generator
        :chart-id="'line-chart'"
        :dataset-id-key="'label'"
        :chart-data="data"
        :chart-options="chartOptions"
        :height="400"
        :css-classes="'line-chart-unexportable'"
      />

    </vac-card>

  </div>
</template>

<script>
import Actions from "../actions/Actions";
import ExcelPreviewModal from "@/components/actions/ExcelPreviewModal";
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
)

const ExportJsonExcel = require('js-export-excel');
export default {
  name: "TrendsPerTime",
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    isExportable: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Per Time'
    }
  },
  components: {
    ExcelPreviewModal,
    Actions,
    LineChartGenerator
  },
  data() {
    return {
      isPreview: false
    }
  },
  computed: {
    preparedData() {
      const {labels, datasets} = this.data;

      const returnObj = {}
      returnObj.labels = [...labels]
      returnObj.labels.unshift("Brands", "Total")
      returnObj.maxLength = []

      for (let key = 0; key < returnObj.labels.length; key++) {
        returnObj.maxLength[key] = returnObj.labels[key].length;
      }


      returnObj.datasets = datasets.map(data => {
        returnObj.maxLength[0] = Math.max(returnObj.maxLength[0], String(data.label).length)
        returnObj.maxLength[1] = Math.max(returnObj.maxLength[1], String(data.data.reduce((a, b) => (a + b))).length)
        for (let key = 0; key < data.data.length; key++) {
          returnObj.maxLength[key + 2] = Math.max(returnObj.maxLength[key + 2], String(data.data[key]).length)
        }

        return {
          data: [data.label, data.data.reduce((a, b) => (a + b)), ...data.data]
        }
      })

      return returnObj;
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0 // disables bezier curves
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              padding: 20,
              usePointStyle: true,
              pointStyle: 'circle'
            }
          },
        }
      }
    }
  },
  methods: {
    exportToExcel() {
      const {labels, datasets} = this.data;
      const dataSheet = [];

      datasets.forEach(data => {
        const obj = {Retailer: data.label, Total: 0};
        data.data.forEach((info, index) => {
          obj[labels[index]] = info;
          obj.Total += info;
        });
        dataSheet.push(obj);
      });

      labels.unshift('Retailer', 'Total');

      const option = {
        fileName: this.label,
        datas: [
          {
            sheetData: dataSheet,
            sheetName: 'sheet',
            sheetFilter: labels,
            sheetHeader: labels,
            columnWidths: [16],
          },
        ],
      };

      const toExcel = new ExportJsonExcel(option); // new
      toExcel.saveExcel();
      labels.shift()
      labels.shift()
    },

    togglePreview() {
      this.isPreview = !this.isPreview;
    }
  },
}
</script>

<style lang="scss">
.line-chart-exportable {
  height: 700px;
}

.line-chart-unexportable {
  height: 400px;
}
</style>
