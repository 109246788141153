<template>
  <Portal to="body">
    <div class="preview-wrapper">
      <div class="preview-wrapper-sub">
        <div class="excel-preview-close" @click="$emit('disable')"/>
        <div class="excel-preview">
          <div class="excel-preview-header">
            {{ header }}
          </div>

          <table>
            <thead>
            <tr>
              <th :style="{minWidth: '50px'}"/>
<!--              <th v-for="(labels, key) in data.labels" :key="key" :style="{minWidth: data.maxLength[key] * 10 + 'px'}">-->
<!--                {{ String.fromCharCode(65 + key) }}-->
<!--              </th>-->
              <td v-for="(labels, key) in data.labels" :key="key" :style="{minWidth: data.maxLength[key] * 10 + 'px', textAlign: 'center'}">
                {{ labels }}
              </td>
            </tr>
            </thead>
            <tbody>
<!--            <tr>-->
<!--              <td>1</td>-->
<!--              -->
<!--            </tr>-->
            <tr v-for="(sets, key) in data.datasets" :key="key">
              <td>{{ key + 1 }}</td>
              <td v-for="(data, key) in sets.data" :key="key">
                {{ data }}
              </td>
            </tr>
            </tbody>
          </table>

          <div class="excel-preview-button" @click="$emit('exportToExcel')">
            Download Excel file
          </div>
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import {Portal} from "portal-vue";

export default {
  name: "ExcelPreviewModal",

  components: {
    Portal
  },

  props: {
    header: {
      type: String,
      default: ""
    },
    data: {
      type: Object,
      default: () => {
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-wrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.68);

  &-sub {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 6px;
    transform: translateX(calc(50vw - 50%)) translateY(calc(50vh - 50%));
    position: absolute;
    opacity: 1;
    max-width: 1100px;
  }
}

.excel-preview {
  margin: 40px;
  max-width: 1100px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  text-align: start;

  &-header {
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  &-button {
    display: flex;
    align-self: flex-end;
    align-items: center;
    padding: 12px 30px;
    background: #000000;
    border-radius: 6px;
    margin-top: 32px;
    color: white;
    cursor: pointer;

    &:hover {
      background-color: #1e1e1e !important;
    }
  }

  &-close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 19px;
    right: 19px;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(45deg);
    }

    &::after {
      position: absolute;
      content: "";
      top: 12px;
      left: 4px;
      background-color: #727e85;
      width: 18px;
      height: 2px;
      transform: rotate(-45deg);
    }

    &:hover {
      &::before {
        background-color: black;
      }

      &::after {
        background-color: black;
      }
    }
  }

  table {
    overflow: auto;
    display: block;
    max-height: 355px;
    position: relative;
    border-collapse: separate;
    max-width: 100%;
    width: 100%;
    min-width: 0;
    margin-top: 16px;

    tbody {
      tr {
        td {
          align-items: center;
          padding: 5px;
          text-align: start;
        }
      }
    }
  }
}

td,
th {
  border: 0.5px solid #727e85;
}
</style>
