<template>
  <div class="btn-open--modal btn-open--excel" @click="exportToExcel">
    <!--<i class="fa fa-file-excel-o"></i>-->
    Excel
  </div>
</template>

<script>
export default {
  name: 'ButtonExcel',
  props: {
    exportToExcel: {
      type: Function,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>
